import { PlatformBuilder } from '@hauru/src/utils/platformBuilder'

import BaseApp from '@hauru/src/module'
import Switch from '@hauru/src/applications/switch/module'
import AssetManagement from '@hauru/src/applications/asset-management/module'
import ClientEdition from '@hauru/src/applications/client-edition/module'
import auth0Login from '@hauru/src/features/auth/auth0-login.js'
import config, { extendConfig } from '@hauru/src/config.js'
import clientConfig from './config.js'
import './index.css'

import MandarineBaseApp from './applications/base/module.js'
import MandarineAssetManagement from './applications/asset-management/module.js'

extendConfig(clientConfig)

const authConfig = auth0Login(config.auth)
const platform = new PlatformBuilder()
  .initFirebase(config.firebase)
  .addApp(BaseApp, MandarineBaseApp)
  .addApp(Switch)
  .addApp(AssetManagement, MandarineAssetManagement)
  .addApp(ClientEdition)
  .setAuth(authConfig)

const app = platform.build()
app.mount('main')

window.app = app
window.platform = platform
  