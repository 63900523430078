import { ref } from 'vue'
const templates = ref([])
export function useTemplates() {
  const loaded = ref(true)
  templates.value = Object.entries(window.platform.apps
    .find((a) => a.name === 'digital-edition').pages)
    .filter(([k,v]) => v.additions?.theme)
    .reduce((acc, [k, v]) => {
      acc[k.split('/').slice(-1)] = v.additions.theme
      return acc},
    {})
  return {
    templates,
    loaded,
  }
}
