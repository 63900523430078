import sorting from '../../sorting'
import { pagesFromGlobRecord, componentsFromGlobRecord, translationFromGlobRecord } from '@hauru/src/utils/platformBuilder'
const config = {
  extend: [
    "app-asset-management",
    "app-client-edition",
    "plugin-white-label"
  ],
  userflow: [
    "overview",
    "performance",
    "contribution",
    "attribution",
    "allocation",
    "details"
  ],
  formats: {
    contrib_sensi_fund: ".2f",
    contrib_sensi_benchmark: ".2f",
    contrib_sensi_fund_filter: ".2f",
    exposure_fund: ".2%",
    exposure_benchmark: ".2%",
    nav: ".2f",
    trackingError: ".2%",
    informationRatio: ".2%"
  }
}
config.sorting = sorting
config.client = 'mandarine'
config.groupingOptions = ['dedicated_fund', 'category']
config.tagField = 'category'
config.fundSelection = [
  'fund_name',
  'fund_type',
  'pea_eligible',
  'amf_classification',
  'isin_list',
  'dedicated_fund',
  'management_company',
  'managers',
  'category'
]
config.characteristicsSchema = `
  characteristics {
    share_letter
    fund_type
    pea_eligible
    management_company
    managers
    amf_classification
    orientation
    bloomberg_code
    settlement
    minimum_initial_subscription
    initial_charge
    redemption_fee
    management_fee
    performance_fee
    custodian
    fund_admin
    assignment_distributable_sums
    valuation_frequency
    category
    managers
  }`
config.settingsSchema = `
  settings`
config.displayManagers = true
config.displayManagementComment = true
config.combine_in_out_mvt = true
config.defineColumns = (characteristics) => ['name', 'weight', 'contribution', 'price_change', 'pnl', 'quantity', 'price']

export default {
  pages: pagesFromGlobRecord(import.meta.globEager('./pages/**/*.vue'), './pages'),
  components: componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  translations: translationFromGlobRecord(import.meta.globEager('./translations/**/*.yml')),
  config,
}