import { pagesFromGlobRecord, componentsFromGlobRecord, translationFromGlobRecord } from '@hauru/src/utils/platformBuilder'

export default {
  /**
   * Pages components to map to the router
   */
  pages: pagesFromGlobRecord(import.meta.globEager('./screens/**/*.vue'), './screens'),
  /**
   * Components to add to the app
   */
  components: componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  /**
   * Translations to add
   */
  translations: translationFromGlobRecord(import.meta.globEager('./translations/**/*.yml')),
}
